.content--index,
.content--share,
.secret-form--index {
  display: contents;
}

.textarea--index {
  grid-column: 2;
  grid-row: 1;
}

.secret-form-options--index {
  grid-column: 2;
  grid-row: 2;
  background: var(--color-mid-grey);
  padding: calc(5 * var(--spacing-unit)) calc(3 * var(--spacing-unit));
  box-sizing: border-box;
  max-height: 230px;
  align-self: flex-end;
  width: 100%;
}

.secret-form-options__input--index {
  margin-bottom: calc(2.5 * var(--spacing-unit));
}

.secret-form-options-label__generator--index {
  text-decoration: underline;
  cursor: pointer;
}

/* share page */
.content__url {
  grid-column: 2;
  grid-row: 1;
  word-break: break-all;
  padding: var(--outer-padding);
}

.content__passphrase {
  grid-column: 2;
  grid-row: 2;
  color: var(--color-purple);
  padding: var(--outer-padding);
}

.content-passphrase__note {
  color: var(--color-black);
}

.content-passphrase__passphrase {
  word-break: break-all;
}

.content__actions {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column: 2;
  grid-row: 3;
}

@media (max-width: 980px) {
  .content--index,
  .content--share,
  .secret-form--index {
    display: flex;
    flex-direction: column;
  }

  .content--index * {
    grid-column: auto;
    grid-row: auto;
  }
}

@media (max-width: 375px) {
  .secret-form-options--index {
    padding: calc(3 * var(--spacing-unit)) calc(2 * var(--spacing-unit));
  }

  .content__actions {
    display: flex;
    flex-direction: column;
  }
}
