.button {
  display: block;
  border: none;
  position: relative;
  margin: 0;
  border-radius: 0;
  font-family: var(--font-family-default);
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 1px;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
  transition: background-color 150ms linear;
  min-height: 150px;
}

.button > .icon {
  position: absolute;
  right: calc(5 * var(--spacing-unit));
  transition: right 140ms linear, opacity 80ms ease-in-out;
  opacity: 1;
}

.button--disabled > .icon {
  right: -20px; /* aka hidden */
  opacity: 0;
}

.button.focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--color-black);
}

.button--primary {
  background-color: var(--color-cyan);
  color: var(--color-white);
}

.button--disabled {
  background-color: var(--color-light-grey);
  color: var(--color-mid-grey);
  cursor: not-allowed;
  pointer-events: none;
}

.button--dangerous {
  background-color: var(--color-red);
  color: var(--color-white);
}
