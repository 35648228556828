.content--reveal,
.content--revealed,
.content--removed,
.content--reveal form {
  display: contents;
}

.content--reveal .passphrase {
  flex: 1 1 auto;
  background: var(--color-mid-grey);
  padding: 40px 30px;
  box-sizing: border-box;
  display: flex;
  grid-column: 2;
  grid-row: 1 / span 2;
}

.content--reveal .button {
  grid-column: 2;
  grid-row: 3;
}

.content--reveal .passphrase.passphrase--error {
  background: var(--color-red);
  color: var(--color-white);
}

.content--reveal .passphrase span {
  align-self: center;
  width: 100%;
}

.content--removed {
  color: var(--color-white);
}

.content--removed .t--beta {
  background: var(--color-red);
  grid-column: 2;
  grid-row: 1 / span 3;
  margin: 0;
  padding: calc(3 * var(--spacing-unit));
  flex: 1 0 auto;
}

.content--fetching {
  color: var(--color-white);
  background: var(--color-mid-grey);
}

.content--revealed .textarea {
  grid-column: 2;
  grid-row: 1 / span 3;
}

@media (max-width: 980px) {
  .content--reveal,
  .content--revealed,
  .content--removed,
  .content--reveal form {
    display: flex;
    flex-direction: column;
    min-height: 40vh;
  }
}
