.select {
  position: relative;
}

.select__content {
  appearance: none;
  width: 100%;
  border: 1px solid var(--color-black);
  color: var(--color-black);
  background: var(--color-white);
  font-size: 1rem;
  font-family: inherit;
  padding: calc(1.5 * var(--spacing-unit));
  box-sizing: border-box;
  outline: none;
  border-radius: 0;
}

.select__content:focus {
  box-shadow: inset 0 0 0 1px var(--color-black);
}

.select__icon {
  position: absolute;
  right: 0;
  pointer-events: none;
  padding: 15px;
}
