.header {
  color: var(--color-white);
  display: contents;
}

.header:first-child {
  grid-column: 1;
  grid-row: 1;
}

.header:last-child {
  grid-column: 1;
  grid-row: 2;
}

.header > * {
  background-color: var(--color-purple);
  background: var(--color-purple);
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    var(--color-purple) 50%,
    var(--color-yellow) 50%
  );
  transition: background-position 300ms, color 1200ms;
}

.header--revealed > *,
.header--share > * {
  color: var(--color-black);
  background-position: -100% 0;
}

.header__info {
  display: flex;
  flex-direction: column;
  padding: var(--outer-padding);
}

.header-info__subtitle {
  margin-top: auto;
}

.header__title {
  padding: var(--outer-padding);
  font-weight: bolder;
}

.header__title > * {
  text-decoration: none;
}

@media (max-width: 980px) {
  .header {
    grid-column: auto;
    grid-row: auto;
  }
}
