@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Mono'), local('IBMPlexMono'),
  url('/fonts/ibm-plex-mono-v5-latin-regular.woff2') format('woff2'),
  url('/fonts/ibm-plex-mono-v5-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Sans'), local('IBMPlexSans'),
  url('/fonts/ibm-plex-sans-v7-latin-regular.woff2') format('woff2'),
  url('/fonts/ibm-plex-sans-v7-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
  url('/fonts/ibm-plex-sans-v7-latin-700.woff2') format('woff2'),
  url('/fonts/ibm-plex-sans-v7-latin-700.woff') format('woff');
}

h1,
h2 {
  margin: 0;
}

.t--alpha {
  font-family: var(--font-family-default);
  font-size: 3.625rem; /* 58px */
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 1px;
}

.t--beta {
  font-family: var(--font-family-default);
  font-size: 2.125rem; /* 34px */
  line-height: 1.25em;
  font-weight: 400;
  margin-top: calc(var(--spacing-unit) * 0.5);
}

.t--gamma {
  font-family: var(--font-family-tech);
  font-size: 1rem; /* 16px */
  line-height: 1.313em;
  font-weight: 400;
}

.t--delta {
  font-family: var(--font-family-tech);
  font-size: 0.875rem; /* 14px */
  line-height: 1.286em;
  font-weight: 400;
}

.t--epsilon {
  font-family: var(--font-family-tech);
  font-size: 0.75em; /* 12px */
  line-height: 1.333em;
  font-weight: 400;
}
