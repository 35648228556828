::placeholder {
  color: var(--color-purple);
  font-size: 1rem;
  line-height: 1.5em;
}

.textarea {
  display: grid;
  padding: var(--outer-padding);
  box-sizing: border-box;
  grid-template-rows: auto 20px;
}

.textarea--focused {
  box-shadow: inset 0 0 0 2px var(--color-black);
}

.textarea__inner {
  margin: 0;
  border-radius: 0;
  border: 0;
  box-sizing: border-box;
  background: var(--color-white);
  color: var(--color-black);
  font-size: 2.125rem;
  line-height: 1.25em;
  outline: none;
  resize: none;
}

.textarea__character-count {
  text-align: right;
  color: var(--color-light-grey);
}
