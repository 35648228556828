/* General rules */

/*
Media Queries:
980px tablet
375px mobile
*/

mark,
::selection {
  background: var(--color-yellow);
}

body {
  font-family: var(--font-family-default);
  margin: 0;

  /* Typography */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "kern" 1, "liga" 1;
}

a {
  color: inherit;
}

/* Utility classes */
.util-container {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto 150px;
}

@media (max-width: 980px) {
  .util-container {
    grid-template-columns: 100%;
    grid-template-rows: min-content auto min-content min-content;
  }
}

/* legal pages */
.legal-container {
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-template-rows: auto 150px;
  background: var(--color-light-grey);
}

.legal-container .footer {
  grid-column: 1 / span 2;
  grid-row: auto;
}

.legal {
  background: var(--color-purple);
}

.legal > * {
  color: var(--color-white);
  padding: var(--outer-padding);
}

.legal h2 {
  font-family: var(--font-family-default);
  font-size: 1rem; /* 16px */
  line-height: 1.313em;
  font-weight: 400;
  margin: 0;
  padding-top: 0;
}

.legal-header .t--alpha {
  text-decoration: none;
  font-weight: 600;
}

.legal p {
  font-family: var(--font-family-tech);
  font-size: 0.875rem; /* 14px */
  line-height: 1.286em;
  font-weight: 400;
  margin: 0;
  padding-top: 0;
}

@media (max-width: 980px) {
  .legal-container {
    grid-template-columns: 100%;
  }
}
