label {
  font-family: var(--font-family-tech);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin-bottom: calc(1 * var(--spacing-unit));
}
